import TopPanel from "../components/TopPanel";
import AdminProfile from "../components/AdminProfile";
import WhiteListForm from "../components/WhiteListForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Admin = () => {
    const { isAdmin } = useSelector((state) => state.web3);
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
        }
    }, [isAdmin, navigate])

    return(
        <>
            <div className="admin--page">
                <TopPanel admin />
                <div className="content--container main--container">
                    <div className="main--block">
                        <AdminProfile />
                        <WhiteListForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin

const ProfileUnconnected = () =>{
    return(
        <div className="person__inf--unconnected">
            <span>!</span>
            <div>
                Connect wallet and Mint NFT Pass ID
            </div>
        </div>
    )
}
export default ProfileUnconnected
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addWhiteList } from "../store/reducer";
import Input from "../UI/Input";


const WhiteListForm = () => {
    const [wallet, setWallet] = useState("")
    const dispath = useDispatch()

    return (
        <>
            <div className="wl--form">
                <div className="wl--form__body">
                    <div className="wl--form__title">
                        Marketplace loyalty program
                    </div>
                    <div action="" className="wl--form__content">
                        <div className="wl--form__buttons">
                            <div className="wl--form__btn wl--form__btn_active">
                                Enable
                            </div>
                            <div className="wl--form__btn">
                                Disable
                            </div>
                        </div>
                        <div className="wl--form__inputs">
                            <div className="inputs--block__title">
                                White list
                            </div>
                            <Input label="Add wallet" type="text" onChange={(e) => setWallet(e.target.value)} onClick={() => dispath(addWhiteList(wallet))} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WhiteListForm
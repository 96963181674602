import { createSlice, combineReducers, createAsyncThunk, isPending, isRejected, isFulfilled } from '@reduxjs/toolkit'
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import tokenABI from "../assets/abi/token.json";
import nftABI from "../assets/abi/nft.json";
import passABI from "../assets/abi/passId.json"
import WalletConnect from "@walletconnect/web3-provider";
import { changeStep } from "./actions"
import Web3Modal from "web3modal";
import { Contract, ethers } from 'ethers';
import _ from 'lodash';

const web3State = {
  loading: false,
  library: null,
  provider: null,
  signer: null,
  signerAddr: null,
  web3Modal: null,
  tokenContract: null,
  nftContract: null,
  passNftContract: null,
  marketPlace: null,
  selfID: null,
  balance: null,
  selfNfts: [],
  allNfts: [],
  inWhiteList: false,
  isAdmin: false,
  tokenName: 'TKN',
}

const UIState = {
  step: -1
}

const persistConfig = {
  key: 'root',
  storage
};

export const setNewPrices = createAsyncThunk("web3/set-prices", async (_, { getState }) => {
  const { web3 } = getState();
  const contract = web3.nftContract;


  let allNfts = await Promise.all(web3.allNfts.map(async (nft) => {
    const newPrice = ethers.utils.formatEther(await contract.getProductPrice(nft.id));
    return {
      ...nft,
      price: newPrice
    }
  }))

  return allNfts
})

export const addWhiteList = createAsyncThunk("web3/add-wl", async (wallet, { getState }) => {
  const { web3 } = getState();
  const tx = await web3.passNftContract.addWhitelist([wallet])
  await tx.wait()
})

export const transferNft = createAsyncThunk("web3/send-nft", async ({ nftID, receiver }, { getState }) => {
  const { web3 } = getState();
  const contract = web3.nftContract;

  const tx = await contract.transferFrom(web3.signerAddr, receiver, nftID)
  await tx.wait()

  return nftID
})

export const buyNft = createAsyncThunk("web3/buy-prof", async ({ id, price }, { getState }) => {
  const { web3 } = getState();

  const token = web3.tokenContract
  const contract = web3.nftContract;
  const truePrice = ethers.utils.parseEther(price).toString();

  const approveTx = await token.approve(contract.address, truePrice)
  await approveTx.wait()

  console.log("Approved");

  const tx = await contract.buyNFT(web3.signerAddr, id, truePrice);
  await tx.wait();

  let fileNames = [
    "pizza",
    "three_pizzas",
    "certificate_for_stationery",
    "usb_mug",
    "humidifier",
    "headset",
    "game_mouse",
    "smart_speaker",
    "mechanical_keyboard",
    "power_bank",
    "apple_airpods",
    "apple_watch",
  ]

  let names = [
    "pizza",
    "three pizzas",
    "certificate for stationery",
    "usb mug",
    "humidifier",
    "headset",
    "game mouse",
    "smart speaker",
    "mechanical keyboard",
    "power bank",
    "apple airpods",
    "apple watch",
  ]

  let allNfts = (await Promise.all(_.times(fileNames.length).map(async (id) => {
    const price = ethers.utils.formatEther(await contract.getProductPrice(id));
    console.log(" nft ID: ", id);
    return {
      id,
      name: names[Number(id)],
      price,
      uri: require(`../assets/nft-list/${fileNames[Number(id)]}.png`).default,
    }
  })))

  let selfNfts = await Promise.all((await contract.getIdNFT(web3.signerAddr)).map(async (id) => {
    let nftId = Number(id);
    const productId = Number(await contract.nftIDtoProductID(nftId));

    return {
      id: nftId,
      name: names[Number(productId)] + " #" + nftId,
      uri: require(`../assets/nft-list/${fileNames[Number(productId)]}.png`).default,
    }
  }))

  return {
    tokenBalance: await token.balanceOf(web3.signerAddr),
    allNfts,
    selfNfts
  }
})

export const connectWallet = createAsyncThunk("web3/connect", async () => {
  if (!window.ethereum) {
    return
  }
  const providerOptions = {
    coinbasewallet: {
      package: CoinbaseWalletSDK, 
      options: {
        appName: "Loyalty",
        infuraId: "ab0cd2ee943b4b6c8c5f3e64e24548ff"
      }
    },
    walletconnect: {
      package: WalletConnect, 
      options: {
        infuraId: "ab0cd2ee943b4b6c8c5f3e64e24548ff"
      }
    }
  };

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions
  });

  const provider = await web3Modal.connect()
  const library = new ethers.providers.Web3Provider(provider, 'any')

  const { chainId } = await library.getNetwork();

  if (chainId !== 80001) {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
          chainId: "0x" + Number(80001).toString(16),
          rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
          chainName: "Mumbai Testnet",
          nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18
          },
          blockExplorerUrls: ["https://rpc-mumbai.maticvigil.com/"]
      }]
    });
    //   window.ethereum.request({
    //     method: "wallet_addEthereumChain",
    //     params: [{
    //         chainId: "0x89",
    //         rpcUrls: ["https://rpc-mainnet.matic.network/"],
    //         chainName: "Matic Mainnet",
    //         nativeCurrency: {
    //             name: "MATIC",
    //             symbol: "MATIC",
    //             decimals: 18
    //         },
    //         blockExplorerUrls: ["https://polygonscan.com/"]
    //     }]
    // });
  }

  const signer = await library.getSigner();
  const signerAddr = await signer.getAddress()

  const tokenContract = new Contract("0x0B257aabf7C54Bdc361c89154740af671be11040", tokenABI, signer)
  const nftContract = new Contract("0x996a047B424B613A88F8B1b672c67e7A3714b001", nftABI, signer)
  const passIdContract = new Contract("0x731Fe16851F71ce577817f4814C16368dbCa0331", passABI, signer);

  await tokenContract.attach("0x0B257aabf7C54Bdc361c89154740af671be11040");
  await nftContract.attach("0x996a047B424B613A88F8B1b672c67e7A3714b001");
  await passIdContract.attach("0x731Fe16851F71ce577817f4814C16368dbCa0331")

  const inWhiteList = await passIdContract.whitelistUsers(signerAddr);

  const isAdmin = await nftContract.owner() === signerAddr
  let balance = Number(await passIdContract.balanceOf(signerAddr));
  let passId;

  if (balance >= 1) {
    passId = Number(await passIdContract.getPassID(signerAddr));
  }
  const tokenName = await tokenContract.symbol()

  let tokenBalance = Number(ethers.utils.formatEther(
    await tokenContract.balanceOf(signerAddr)
  ));

  let fileNames = [
    "pizza",
    "three_pizzas",
    "certificate_for_stationery",
    "usb_mug",
    "humidifier",
    "headset",
    "game_mouse",
    "smart_speaker",
    "mechanical_keyboard",
    "power_bank",
    "apple_airpods",
    "apple_watch",
  ]

  let names = [
    "pizza",
    "three pizzas",
    "certificate for stationery",
    "usb mug",
    "humidifier",
    "headset",
    "game mouse",
    "smart speaker",
    "mechanical keyboard",
    "power bank",
    "apple airpods",
    "apple watch",
  ]

  let allNfts = (await Promise.all(_.times(fileNames.length).map(async (id) => {
    const price = ethers.utils.formatEther(await nftContract.getProductPrice(id));
    
    return {
      id,
      name: names[Number(id)],
      price,
      uri: require(`../assets/nft-list/${fileNames[Number(id)]}.png`).default,
    }
  })));

  let selfNfts = await Promise.all((await nftContract.getIdNFT(signerAddr)).map(async (id) => {
    let nftId = Number(id);
    const productId = Number(await nftContract.nftIDtoProductID(nftId));

    return {
      id: nftId,
      name: names[Number(productId)],
      uri: require(`../assets/nft-list/${fileNames[Number(productId)]}.png`).default,
    }
  }))

  console.log("selfNfts: ", selfNfts);

  return {
    web3Modal,
    provider,
    library,
    signer,
    signerAddr,
    passIdContract,
    tokenContract,
    nftContract,
    passId,
    tokenBalance,
    selfNfts,
    allNfts,
    inWhiteList,
    isAdmin,
    tokenName,
  }
})

export const disconnectWallet = createAsyncThunk("web3/disconnect", async (_, { getState }) => {
  let { web3 } = getState();
  await web3.web3Modal?.clearCachedProvider();
})

export const mintMyPass = createAsyncThunk("web3/mint-pass", async (_, { getState }) => {
  const { web3 } = getState();

  const tx = await web3.passNftContract.freeMint()
  await tx.wait();
})

export const web3slice = createSlice({
  name: "web3reducer",
  initialState: web3State,
  reducers: {
    setSelfId: (state, { payload }) => {
      console.log("Set self ID");
      state.selfID = payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(buyNft.fulfilled, (state, { payload }) => {
      state.selfNfts = payload.selfNfts;
      state.allNfts = payload.allNfts;
      state.balance = ethers.utils.formatEther(payload.tokenBalance)
    });

    builder.addCase(connectWallet.fulfilled, (state, { payload }) => {
      state.provider = payload.provider
      state.library = payload.library
      state.signer = payload.signer
      state.signerAddr = payload.signerAddr
      state.web3Modal = payload.web3Modal;
      state.tokenContract = payload.tokenContract;
      state.nftContract = payload.nftContract;
      state.passNftContract = payload.passIdContract;
      state.balance = payload.tokenBalance
      state.selfID = payload.passId;
      state.selfNfts = payload.selfNfts;
      state.allNfts = payload.allNfts;
      state.inWhiteList = payload.inWhiteList;
      state.isAdmin = payload.isAdmin
      state.tokenName = payload.tokenName
    })

    builder.addCase(disconnectWallet.fulfilled, (state) => {
      state.provider = null
      state.library = null
      state.signer = null
      state.signerAddr = null
      state.web3Modal = null
      state.tokenContract = null
      state.nftContract = null
      state.passNftContract = null
      state.selfID = null;
    })

    builder.addCase(transferNft.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.selfNfts = state.selfNfts.filter((nft) => nft.id !== payload)
    })

    builder.addCase(setNewPrices.fulfilled, (state, payload) => {
      state.allNfts = payload;
    })

    builder.addCase(addWhiteList.fulfilled, () => {
      console.log("Add");
    })

    builder.addMatcher(isPending, (state) => {
      state.loading = true
    })

    builder.addMatcher(isRejected, (state, { error }) => {
      console.log(error);
      state.loading = false
    })

    builder.addMatcher(isFulfilled, (state) => {
      state.loading = false
    })
  }
})

export const uiSlice = createSlice({
  name: "UI",
  initialState: UIState,
  extraReducers: (builder) => {
    builder.addCase(changeStep, (state, { payload }) => {
      state.step = payload
    })
  }
})


export const {
  setSelfId
} = web3slice.actions;

export const rootReducer = combineReducers({
  web3: web3slice.reducer,
  ui: uiSlice.reducer,
})

export const persistedReducer = persistReducer(persistConfig, rootReducer);

